<template>
  <div>
    <div class="fs-container">
      <div class="p-container"  :style="{
        'height': isWholeSale ? '120px' : '',
        'min-height': isWholeSale ? '120px' : '' }" >
        <h1 class="top-title mb-3 mb-3" v-if="isWholeSale">BÁN SỈ SẢN PHẨM BASEUS</h1>
        <h1 class="top-title" v-else> {{ $t('DevicePage.PhoneDevice') }}</h1>
        <ul class="category menuProduct" v-if="!isWholeSale">
          <li style="position: relative;" v-for="(c, stt) in categories" :key="stt" :class="c.slug == 'tat-ca' || c.slug == ''
            ? `active${c.childrent && c.childrent.length && c.childrent.length > 0
              ? ' has-child'
              : ''
            }`
            : `${c.childrent && c.childrent.length && c.childrent.length > 0
              ? ' has-child'
              : ''
            }`
          " role="button">
            <span @click="goTo(c)" v-if="!c.childrent || c.childrent.length == 0">
              <img class="icon-menu" :src="require(`@/assets/images/menu/${c.icon}`)" />{{ $t(c.category_name) }}</span>
            <span v-else>
              <span class="span-menu-img">
                <img class="icon-menu" :src="require(`@/assets/images/menu/${c.icon}`)" />
              </span>
              <span @mouseover="showMenu(c.slug, 'cate')" @click="goTo(c)">{{ $t(c.category_name) }}</span>
              <img @click="showMenu(c.slug, 'cate')" v-if="c.icon2" class="icon-menu"
                :src="require(`@/assets/images/menu/${c.icon2}`)" />
            </span>
            <div @mouseleave="showMenu(c.slug, 'cate')" v-if="c.childrent && c.childrent.length && c.childrent.length > 0"
              style="z-index: 9; margin-top: 10px; " :class="
                c.slug === openMenuCate && isOpen ? 'click-menu-items ' : 'menu-item-drop'
              ">
              <div style="border-radius: 1.5rem" class="menu-wrap">
                <div class="wrap__child bv-example-row">
                  <b-row>
                    <b-col md="12" sm="12" xs="12" v-for="(c2, stt2) in c.childrent" :key="stt2">
                      <div :class="stt2 === 0
                        ? 'menu-sub-item mb-0 pb-0 mt-2'
                        : 'menu-sub-item mb-0 pb-0'
                      ">
                        <div class="d-flex align-items-center item-menu-c2">
                          <div style="flex: 1" @click="goTo(c2, c)">
                            <span class="span-menu-img">
                              <img v-if="c2.icon" class="menu-img-icon" :src="
                                require(`@/assets/images/menu/${c2.icon}`)
                              " />
                            </span>
                            <span :class="c2.slug == openMenuSub || c2.slug == $route.params.Slug ? 'active' : ''">{{
                              c2.category_name
                            }}</span>
                          </div>
                          <img @click="showMenu(c2.slug, 'sub-menu')"
                            :class="openMenuSub == c2.slug ? 'arrow-up' : 'arrow-down'" class="mr-1" :src="
                              openMenuSub === c2.slug
                                ? require(`@/assets/images/menu/arrow_up.png`)
                                : require(`@/assets/images/menu/arrow_down.png`)
                            " />

                        </div>
                        <p v-if="
                          openMenuSub === c2.slug
                        " v-for="(c3, stt3) in c2.childrent"
                          :class="stt3 === c2.childrent.length - 1 ? 'mt-2 item-menu-c3 ml-5 mb-3' : 'mt-2 item-menu-c3 ml-5'">
                          <span :class="c3.slug == $route.params.Slug ? 'active' : ''" @click="goTo(c3, c, c2)">{{
                            $t(c3.category_name)
                          }}</span>
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="row" v-if="!isWholeSale">
          <div class="col-12 top-product">
            <b-carousel controls indicators>
              <b-carousel-slide img-src="@/assets/images/banners/product/OPPO_H.png" />
              <b-carousel-slide img-src="@/assets/images/banners/product/home-slid-2.png" />
            </b-carousel>
          </div>
        </div>
      </div>
    </div>
    <div class="fs-container">
      <div class="p-container">
        <b-row>
          <b-col xl="2" class="d-none d-xl-block pl-0">
            <b-row>
              <b-col xl="6" sm="6" xs="6">
                <h3 class="title-left-order pt-3 pb-2">
                  {{ $t('DevicePage.Filter') }}
                </h3>
              </b-col>
              <b-col xl="6" sm="6" xs="6">
                <span class="clear-filer" role="buton" @click="clearFiler()" v-if="isFilter">Xóa tất cả</span>
              </b-col>
            </b-row>
            <span v-for="(f, stt) in filter_config" :key="stt">
              <b-row class="filter-card" v-b-toggle.collapse-1 aria-controls="collapse-3" v-if="!isWholeSale">
                <b-col md="10" sm="10" xs="10">
                  <label class="header-filter">{{ f.name }}</label>
                </b-col>
                <b-col md="2" sm="2" xs="2">
                  <div :class="f.isShow ? 'bt-tonggle-expand' : 'bt-tonggle-collspan'"
                    :aria-expanded="f.isShow == true ? 'true' : 'false'"></div>
                </b-col>
              </b-row>
              <b-collapse v-model="f.isShow" id="collapse-1" v-if="!isWholeSale">
                <b-row class="filter-item" v-for="(v, stt2) in f.values" :key="stt2" @change="changeFilter()">
                  <b-col md="10" sm="10" xs="10">
                    <label class="label-filter" :for="'check-' + stt2">{{ v }}</label>
                  </b-col>
                  <b-col md="2" sm="2" xs="2" class="text-right item-mr-3">
                    <b-form-checkbox :id="'check-' + stt2" :value="v" v-model="filterCheckeds[f.code]">
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-collapse>
              <hr />
              <span v-for="(f2, st2) in f.children">
                <b-row class="filter-card mt-1" v-b-toggle.collapse-2 aria-controls="collapse-4">
                  <b-col md="10" sm="10" xs="10">
                    <label class="header-filter">{{ f2.name }}</label>
                  </b-col>
                  <b-col md="2" sm="2" xs="2">
                    <div :class="f2.isShow ? 'bt-tonggle-expand' : 'bt-tonggle-collspan'"
                      :aria-expanded="f2.isShow ? true : false"></div>
                  </b-col>
                </b-row>
                <b-collapse v-model="f2.isShow" id="collapse-2">
                  <b-row class="filter-item" v-for="(v2, st3) in f2.values" :key="st3" @change="changeFilter()">
                    <b-col md="10" sm="10" xs="10">
                      <label class="label-filter" :for="'check2-' + st3">{{ v2 }}</label>
                    </b-col>
                    <b-col md="2" sm="2" xs="2" class="text-right item-mr-3">
                      <b-form-checkbox :id="'check2-' + st3" ref="checkgroup" :value="v2"
                        v-model="filterCheckeds[f2.code]"></b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-collapse>
              </span>
            </span>

            <div class="clearfix mt-5">&nbsp;</div>
            <h3 class="title-left-order">{{ $t('DevicePage.SortedBy') }}</h3>
            <div class="order asc">
              <label for="sort1" class="header-filter">{{ $t('DevicePage.PriceHighToLow') }}</label>
              <b-form-radio id="sort1" v-model="sort" value="price|desc" @change="radioChang()" class="float-right"
                name="order" />
            </div>
            <div class="order desc">
              <label for="sort2" class="header-filter"> {{ $t('DevicePage.PriceLowToHigh') }}</label>
              <b-form-radio id="sort2" v-model="sort" value="price|asc" @change="radioChang()" class="float-right"
                name="order" />
            </div>
            <!-- <div class="order sales">
              {{ $t('DevicePage.GreatDeals') }}
              <b-form-radio v-model="sort" value="sales" class="float-right" name="order" />
            </div> -->
            <!-- <div class="order vote">
              {{ $t('DevicePage.Evaluate') }}
              <b-form-radio v-model="sort" value="rates|desc" @change="radioChang()" class="float-right" name="order" />
            </div> -->
          </b-col>
          <b-col xl="10" class="pl-xl-2">
            <b-row v-if="products">
              <b-col md="6" sm="12" lg="6" xl="4" class="p-1 pt-0" v-for="(p, stt1) in products" :key="stt1">
                <div class="card p-0 shop_home_box">
                  <!-- <div class="card-installment">
                    <div class="card-installment-text">
                      {{ $t('DevicePage.InstallmentPercent') }}
                    </div>
                  </div> -->
                  <a :href="'/shop/san-pham/'+p.origin_type+'/'+p.categories[0].slug+'/'+ p.meta.slug" class="card-body" :title="p.product_name">
                    <div class="footer">
                      <span class="promo" v-if="p.discount_info">{{ $t('DevicePage.PricesAreValid') }}
                        {{ p.discount_info }}</span>
                      <!-- <div class="stars text-right-star">
                        <star-rating v-model="rating['p_' + p.id]" :star-size="19" @rating-selected="updateRate(p)"
                          active-color="#FFCB45" :show-rating="false" />
                        <div class="star-text text-rating">
                          {{ p.rates }} {{ $t('DevicePage.rates') }}
                        </div>
                      </div> -->
                      <span class="price">{{ p.price | number }}đ</span>
                      <span class="base_price" v-if="p.discount_info || p.base_price > p.price">{{ p.base_price | number
                      }}đ</span>
                       <span role="button" v-if="isWholeSale" class="buy-now" @click="BuyNow(p)"> 
                        CHỌN MUA
                      </span>
                      <span role="button" v-else class="buy-now" @click="BuyNow(p)">{{
                        $t('DevicePage.BuyNow')
                      }}</span>
                    </div>
                    <img :src="p.image_url" @click="BuyNow(p)" />
                    <div class="title1" v-if="p.meta" @click="BuyNow(p)">
                      {{ p.meta.supplier }}
                    </div>
                    <div @click="BuyNow(p)" :class="
                      p.origin_type == 'baseus' ? 'title2-baseus' : 'title2'
                    ">
                      <span v-html="p.product_name"></span>
                    </div>
                    <div class="features" v-if="serverParams.columnFilters.Slug == 'baseus'">
                      <span v-for="(c, stt) in p.variants" :key="stt">{{
                        c.title
                      }}</span>
                    </div>
                    <div class="features" v-if="
                      serverParams.columnFilters.Slug != 'baseus' && p.config
                    ">
                      <span v-for="(c, stt) in p.config.filter(
                        (i) => i.on_top == 1
                      )" :key="stt">{{ p.attributes[c.code] }}</span>
                    </div>
                  </a>
                </div>
              </b-col>
              <b-col md="12" sm="12" lg="12" xl="12" class="text-center">
                <div class="viewmore" @click="viewMore()" v-if="
                  totalRecords > serverParams.page * serverParams.pageSize
                ">
                  {{ $t('DevicePage.SeeMore') }}
                </div>
                <div class="viewmore" @click="viewOne()" v-if="
                  totalRecords <= serverParams.page * serverParams.pageSize && totalRecords > serverParams.pageSize">
                  {{ $t('CartPage.SeeOne') }}
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
    <!-- <div class="fs-container" v-if="recent_products.length > 0">
      <div class="p-container">
        <div class="row">
          <div class="col-xl-2 col-lg-12 pt-3">
            <div class="cat-text-left">
              {{ $t('DevicePage.ViewedProducts') }}
            </div>
          </div>
          <div class="col-xl-10 col-lg-12 pl-xl-0">
            <b-carousel class="w-100 product" controls v-if="recent_products">
              <b-carousel-slide v-for="(ps, stt) in recent_products" :key="stt">
                <template v-slot:img>
                  <b-row class="pl-3 pr-3">
                    <b-col
                      md="6"
                      sm="12"
                      lg="6"
                      xl="4"
                      class="mb-3 p-1 pt-3"
                      v-for="(p, stt1) in ps"
                      :key="stt1"
                    >
                      <div class="card p-0 shop_home_box">
                        <div class="card-body" @click="BuyNow(p)" role="button">
                          <div class="footer">
                            <span class="promo" v-if="p.discount_info"
                              >{{ $t('DevicePage.PricesAreValid') }}
                              {{ p.discount_info }}</span
                            >
                            <span class="price">{{ p.price | number }}đ</span>
                            <span class="base_price" v-if="p.discount_info"
                              >{{ p.base_price | number }}đ</span
                            >
                            <span
                              role="button"
                              class="buy-now"
                              @click="BuyNow(p)"
                              >{{ $t('DevicePage.BuyNow') }}</span
                            >
                          </div>
                          <img :src="p.images[0].image_url" />
                          <div class="title1">{{ p.meta.supplier }}</div>
                          <div class="title2">
                            {{ p.product_name }}
                          </div>
                          <div class="features">
                            <span
                              v-for="(c, stt) in p.config.filter(
                                (i) => i.on_top == 1
                              )"
                              :key="stt"
                              >{{ p.attributes[c.code] }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </template>
              </b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </div>
    </div> -->
    <div class="fs-container py-3">
      <div class="p-container">
        <p class="py-3" style="color: #585858;
                  font-size: 34px;
                  line-height: 36px;
                  font-weight: 900;
                ">
          Khám phá<br />
          sản phẩm khác
        </p>
        <div class="row">
          <div v-for="(pd, idx) in productDiscover" :key="idx" class="p-0 d-flex flex-column" :class="`discover-${idx}`">
            <img v-if="pd.image_url && pd.image_url.indexOf('http') == 0" width="100%"
              style="max-height: 200px; border-top-right-radius: 12px" :src="pd.image_url" />
            <img v-else width="100%" style="max-height: 200px; border-top-right-radius: 12px"
              :src="require(`@/assets/images/${pd.image_url}`)" />
            <div class="py-3 px-2 d-flex flex-column justify-content-between" style="flex: 1">
              <div class="mt-2 pt-1">
                <p style="font-size: 18px; font-weight: 800; color: white">
                  {{ pd.name }}
                </p>
                <p class="pt-2" style="font-size: 11px; font-weight: 300; color: white" v-html="pd.description">

                </p>
              </div>
              <p class="mt-3">
                <a :href="pd.link" style="font-size: 12px; font-weight: 600; color: white">Tìm hiểu thêm</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { Navigation, Pagination } from 'swiper';
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2';
import useJwt from '@/auth/jwt/useJwt';
import EventBus from '@/global-components';
import {
  BCarousel,
  BCarouselSlide,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BFormRadio,
  BFormCheckbox, BCollapse, VBToggle,
  BContainer
} from 'bootstrap-vue';
import { OriginType } from '@/libs/acl/config';
import Select2 from 'v-select2-component';
import 'swiper/swiper-bundle.css';
import StarRating from 'vue-star-rating';
import Multiselect from 'vue-multiselect';
SwiperCore.use([Navigation, Pagination]);
import Vue from 'vue';
Vue.directive('b-toggle', VBToggle)
export default {
  components: {
    Swiper,
    SwiperSlide,
    Select2,
    BCarousel,
    BCarouselSlide,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BFormRadio,
    StarRating,
    Multiselect,
    BCollapse, BFormCheckbox
  },
  data() {
    return {
      ProductCategory: [{ id: 'Điện thoại' }],
      products: [],
      devices: [],
      productDiscover: [],
      vw: window.innerWidth,
      recent_products: [],
      totalRecords: 0,
      sort: 'price|desc',
      serverParams: {
        columnFilters: { Slug: '' },
        sort: [],
        page: 1,
        pageSize: 9,
        lang: this.$l,
      },
      rating: {},
      openMenuCate: '',
      openMenuSub: '',
      filterCheckeds: {},
      isFilter: false,
      isOpen: true,
      isWholeSale:false
    };
  },
  computed: {
    categories() {
      return this.$store.getters['products/getCategories'];
    },
    filter_config() {
      return this.$store.getters['products/getFilterConfig']
    },
  },
  watch: {
    $route(to, from) { },
    // serverParams: {
    //   handler() {
    //     this.products = [];
    //     this.$store.dispatch('products/fetchProductsHome', this)
    //   },
    //   deep: true
    // }
   
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted() {
    this.isWholeSale = localStorage.getItem('phoneWholeSale');
    if (this.$route.query.iframe) {
      this.$store.dispatch('app/setIframe', true);
      const headH = document.querySelector('.fs-header');
      if (headH) {
        headH.style.display = 'none';
      }
      const footer = document.querySelector('.fs-footer');
      if (footer) {
        footer.style.display = 'none';
      }
      const chat = document.querySelector('#cs-live-chat');
      if (chat) {
        chat.style.display = 'none';
      }
    }
    if (this.$route.query.m) {
      this.$store.dispatch("app/setSource", 'myitel');
    }
    this.$store.dispatch("products/fetchProductsHome", this);
    this.$store.dispatch("products/fetchCategories", this);
    this.$store.dispatch('products/getDiscover', this);
    this.$store.dispatch('products/fetchFilterConfig', this);
  },
  methods: {
    goTo(c, parent, cat) {
      this.isFilter = false;
      if (cat) {
        sessionStorage.setItem('cat2', cat);
        sessionStorage.setItem('catSlug2', cat ? cat.slug : '');
      }
      else {
        sessionStorage.setItem('catSlug2', c ? c.slug : '');
      }

      if (c.slug != this.$route.params.Slug && c.slug != 'tat-ca') {
        this.products = [];
        this.serverParams.page = 1;
      }
      if (c.slug == 'tat-ca') {
        this.$router.push({ name: 'products', params: { Slug: c.slug } });
      } else {
        var params = {};
        if (parent && parent.slug) {
          params = { originType: c.origin_type, parentSlug: parent.slug, Slug: c.slug }
        }
        else {
          params = { originType: c.origin_type, parentSlug: c.slug, Slug: c.slug }
        }

        this.$router.push({ name: 'shop', params: params });
      }
      this.isOpen = false;
    },
    /**
     * bfd: bổ sung origin_type để phân biệt nguồn lấy dữ liệu
     * @param {*} p :object chi tiết
     */

    BuyNow(p) {
      this.$router.push({
        name: 'product-detail',
        params: {
          originType: p.origin_type,
          slugPath: p.categories[0].slug,
          Slug: p.meta.slug,
        },
      });
    },
    Img(pic) {
      return images[pic];
    },
    openVideo(url) {
      this.modalClass = 'modal__open';
      this.videoId =
        'https://www.youtube.com/embed/' +
        url +
        '?rel=0&amp;autoplay=1&amp;playsinline=1';
    },
    closeVideo() {
      this.videoId = '';
      this.modalClass = '';
    },
    setAnimation() {
      const windowH = window.screen.height;
      const headH = document.querySelector('.fs-header').clientHeight;
      [].slice
        .call(
          document.querySelectorAll(
            '.fs-banner, .choose-pic, .choose-txt, .pack-txt, .pack-info, .i-news-infos, .video-box, .support-pic, .support-txt, .like-pic, .welcome-inr .fs-txt, .welcome-inr .fs-pic, .why-inr .fs-pic, .item-reseaon, .hand-pic, .hand-txt,.about-item, .block-title, .mission-item, .value-item, .promotion-video-box, .vision-inr, .fs-distributions .fs-inr'
          )
        )
        .forEach((elm) => {
          const style = window.getComputedStyle(elm, null);
          const elmH = style.getPropertyValue('height');
          const inputT = windowH - elm.getBoundingClientRect().top;
          if (inputT > 0 && inputT < windowH + parseInt(elmH, 10) - headH / 2) {
            elm.classList.add('fs-ani');
          } else {
            elm.classList.remove('fs-ani');
          }
        });
    },

    /**
     * BFD view more
     *
     */
    viewMore() {
      this.serverParams.page = this.serverParams.page + 1;
      this.$store.dispatch('products/fetchProductsHome', this);
    },
    viewOne() {
      this.products = [];
      this.serverParams.page = 1;
      this.$store.dispatch('products/fetchProductsHome', this);
      window.scrollTo(600, 600);
    },
    updateRate: function (p) {
      this.loading(true);
      useJwt
        .put('update-rate-product', {
          id: p.id,
          rate: this.rating['p_' + p.id],
        })
        .then((response) => {
          p.rates += 1;
          this.loading(false);
        })
        .catch((err) => {
          this.loading(false);
        });
    },
    radioChang: function () {
      this.products = [];
      this.serverParams.page = 1;
      this.$store.dispatch('products/fetchProductsHome', this);
    },
    changeFilter: function () {
      this.products = [];
      this.isFilter = true;
      if (this.filterCheckeds) {
        for (var pro in this.filterCheckeds) {
          this.serverParams.columnFilters[pro] = this.filterCheckeds[pro];
        }
      }
      this.serverParams.page = 1;
      this.$store.dispatch('products/fetchProductCategory', this);
    },
    clearFiler() {
      let self = this;
      self.filterCheckeds = {};
      if (this.filter_config && this.filter_config.length > 0) {
        this.filter_config.forEach(item => {
          self.filterCheckeds[item.code] = [];
          self.serverParams.columnFilters[item.code] = [];
          if (item.children && item.children.length > 0) {
            item.children.forEach(chi => {
              self.filterCheckeds[chi.code] = [];
              self.serverParams.columnFilters[chi.code] = [];
            });
          }
        });
      }
      self.products = [];
      self.serverParams.page = 1;
      self.$store.dispatch('products/fetchProductCategory', self);
    },
    showMenu(slug, type) {
      this.isOpen = true;
      if (type === 'cate') {
        if (this.openMenuCate !== slug) {
          this.openMenuCate = slug;
        } else {
          this.openMenuCate = '';
        }
      } else {
        if (this.openMenuSub !== slug) {
          this.openMenuSub = slug;
        } else {
          this.openMenuSub = '';
        }
      }
    },
    onSelect(option) {
      console.log("Added");
      let index = this.options.findIndex(item => item.library == option.library);
      this.options[index].checked = true;
      console.log(option.library + "  Clicked!! " + option.checked);
    },

    onRemove(option) {
      console.log("Removed");
      let index = this.options.findIndex(item => item.library == option.library);
      this.options[index].checked = false;
      console.log(option.library + "  Removed!! " + option.checked);
    },
    setCateParent(cat) {
      this.catSlug = cat.slug;
    }
  },
};
</script>
<style>
.fs-container {
  background: #f5f5f5;
}


@media screen and (min-width: 1721px) {
  .vue-star-rating {
    display: inline-block !important;
    margin-top: -10px;
  }

  .p-container .footer .stars .star-text {
    padding-top: 0px !important;
    float: right;
    padding-right: 5px !important;
  }

  .p-container {
    min-width: 900px;
    padding: 0 165px
  }
}

@media screen and (max-width: 1720px) {
  .vue-star-rating {
    display: block !important;
    margin-top: -10px;
  }

  .p-container .footer .stars .star-text {
    padding-top: 0px !important;
    float: right;
    padding-right: 5px !important;
  }

  .p-container {
    min-width: 900px;
    padding: 0 120px
  }
}

@media screen and (max-width: 1400px) {
  .p-container {
    min-width: 900px;
    padding: 0 80px
  }
}

@media screen and (max-width: 1360px) {
  .p-container {
    padding: 0 40px;
  }

  .vue-star-rating {
    display: block !important;
    margin-top: -10px;
    padding-right: 0px;
  }

  .shop_home_box .footer .promo,
  .shop_home_box .footer .price,
  .shop_home_box .footer .base_price {
    left: 12px !important;
  }
}

@media screen and (max-width: 1199px) {
  .p-container {
    padding: 0 30px;
  }

  .vue-star-rating {
    display: block !important;
    margin-top: -10px;
    padding-right: 5px;
  }

  .p-container .footer .stars .star-text {
    float: right;
    padding-right: 15px !important;
  }
}

.p-container .top-title {
  padding-top: 62px;
  text-align: center;
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  color: #393e46;
}

.p-container .category {
  padding-top: 30px;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.15);
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.p-container .cat-text-left {
  font-weight: 900;
  font-size: 30px;
  line-height: 45px;
  color: #282828;
  /*padding-top: 9px;*/
}

@media screen and (max-width: 719px) {
  .p-container {
    padding: 0 10px;
  }

  .p-container .top-title {
    padding-top: 60px;
    text-align: center;
    font-weight: 900;
    font-size: 28px;
    line-height: 38px;
    color: #393e46;
  }

  .p-container .category {
    padding-top: 30px;
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.15);
    max-width: 710px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
@media screen and (max-width: 420px) { 
  .p-container .top-title { 
    font-size: 26px; 
  } 
}
.p-container {
  width: 100%;
  min-width: 100%;
  min-height: 400px;
  font-family: 'Roboto';
}


/*.p-container .cat-text-link::after{
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIxIiBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMTIxIDEyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMTIwLjQ5NSA2LjQ5NDk2QzEyMC43NjggNi4yMjE2IDEyMC43NjggNS43NzgzOCAxMjAuNDk1IDUuNTA1MDFMMTE2LjA0IDEuMDUwMjRDMTE1Ljc2NyAwLjc3Njg3NSAxMTUuMzI0IDAuNzc2ODc2IDExNS4wNSAxLjA1MDI0QzExNC43NzcgMS4zMjM2MSAxMTQuNzc3IDEuNzY2ODMgMTE1LjA1IDIuMDQwMTlMMTE5LjAxIDUuOTk5OTlMMTE1LjA1IDkuOTU5NzlDMTE0Ljc3NyAxMC4yMzMyIDExNC43NzcgMTAuNjc2NCAxMTUuMDUgMTAuOTQ5N0MxMTUuMzI0IDExLjIyMzEgMTE1Ljc2NyAxMS4yMjMxIDExNi4wNCAxMC45NDk3TDEyMC40OTUgNi40OTQ5NlpNNi4xMTk1OWUtMDggNi43TDEyMCA2LjY5OTk5TDEyMCA1LjI5OTk5TC02LjExOTU5ZS0wOCA1LjNMNi4xMTk1OWUtMDggNi43WiIgZmlsbD0iI0U5MTgyQyIvPgo8L3N2Zz4K');
}*/
.p-container .cat-text-link {
  padding-top: 12px;
  font-size: 15px;
  line-height: 25px;
  color: #e9182c;
  font-weight: 800;
  text-transform: uppercase;
}

.p-container .top-product {
  padding-top: 10px;
  padding-bottom: 20px;
}

.discover-0 {
  background: #72767c;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  flex: 0 0 40%;
  max-width: 40%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA5LjUgNy41Jz48cG9seWxpbmUgcG9pbnRzPScwLjc1IDQuMzUgNC4xOCA2Ljc1IDguNzUgMC43NScgc3R5bGU9J2ZpbGw6bm9uZTtzdHJva2U6I2ZmZjtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLXdpZHRoOjEuNXB4Jy8+PC9zdmc+);
  width: 18px;
  height: 18px;
}

.discover-1 {
  background: #9aaab9;
  flex: 0 0 20%;
  max-width: 20%;
}

.discover-2 {
  background: #7b7265;
  flex: 0 0 20%;
  max-width: 20%;
}

.discover-3 {
  background: #bc9367;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  flex: 0 0 20%;
  max-width: 20%;
}

.p-container .footer .stars .star-text {
  padding-top: 4px;
  padding-right: 10px;
  font-size: 8px;
  display: inline-block;
}

@media screen and (max-width: 719px) {
  .discover-0 {
    background: #72767c;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    flex: 0 0 65%;
    max-width: 65%;
  }

  .discover-1 {
    background: #9aaab9;
    flex: 0 0 35%;
    max-width: 35%;
  }

  .discover-2 {
    background: #7b7265;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .discover-3 {
    background: #bc9367;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 480px) {
  .discover-0 {
    background: #72767c;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .discover-1 {
    background: #9aaab9;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .discover-2 {
    background: #7b7265;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .discover-3 {
    background: #bc9367;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.p-container .title-left {
  padding: 40px 0px 20px 0px;
  font-weight: 900;
  font-size: 36px;
  width: 100%;
}

.multiselect__tag {
  background: #ed1e23;
}

.multiselect__option--highlight,
.multiselect__option--selected,
.multiselect__option,
.multiselect__option--selected.multiselect__option--highlight {
  background: #fff;
  color: #000;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: none;
  color: #fff;
}

.fake-checked {
  position: absolute;
  right: 20px;
  top: 10px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: #d8d6de solid 1px;
}

.multiselect__tag-icon:after {
  color: #fff !important;
}

.multiselect__option--selected .fake-checked {
  border-color: #ED1F24;
  background-color: #ED1F24;
  box-shadow: 0 2px 4px 0 rgb(237 31 36 / 40%);
}

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  background: none;
}

.multiselect__content-wrapper {
  min-width: 275px;
}
</style>
